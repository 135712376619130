import React, { useContext, useEffect, useState } from "react";
import AppContext from "../utils/app-context";
import StoryPartForm from "../components/story-part-form";
import { useHistory } from "react-router-dom";
import firebase, {analytics} from "../utils/firebase.js";
import ConfirmReplacementDialog from "../components/confirm-replacement-dialog"

import { Typography, Box, Input, Button, FormControl, Container } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import Headbar from "./Headbar";
import Footbar from "./Footbar";
import theme from '../styles/theme'


function SettingsInspector() {

    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();

    const story = state.stories.activeStory 
    const shots = state.stories.activeShotList // this is all undeleted shots
    const settings = state.app.settings 

    const handleDone = () => {
      history.goBack();
    }

    const updateSetting = (new_settings) => {
		dispatch({ type: "UPDATE_STORY_SETTINGS", payload: { "settings": new_settings } });
        const settingsRef = doc(state.stories.activeStory.ref, 'settings', '0')
        settingsRef.set(new_settings, { merge: true })
    }

    const testSettings = () => {
		const new_settings = {"promptsVisible": (!settings.promptsVisible)}
        updateSetting(new_settings)
    }  

    const toggleSetting = (setting_key) => {
      // console.log("TOGGLE KEY", setting_key)
      const new_settings = Object.create(null)
      new_settings[setting_key] = !settings[setting_key]
      // console.log("THESE NEW SETTINGS", new_settings)
      updateSetting(new_settings)
    }  
            

    const cancelBtn = {click: handleDone, type:"tertiary", text:"Cancel"}
    const saveBtn = {click: handleDone, type:"primary", text:"Save"}


      const basic_settings_widget =  <Box sx={{ textAlign:'right', height: '80vh', display:'flex' }}>
          <Box sx={{ maxWidth: "450px", margin:"0 auto", alignSelf:'center' }}>
            <Button variant="contained" size='large' onClick={testSettings}>
               Test
            </Button>
            <Typography variant="brand" sx={{ marginBottom:"15px" }}>
              Current Story Settings
            </Typography>
            {story.title && <Typography variant="body2" sx={{ marginBottom:"25px" }}>
              {story.title}
            </Typography>}

          {Object.entries(settings).map(([setting_key, i]) => (
              <Box key={setting_key}>
                <Typography variant="body2" sx={{ marginBottom:"10px" }}>
                  {setting_key} : {settings[setting_key] ? "True " : "False "}
                  <Button onClick={() => toggleSetting(setting_key)}> o </Button>
                </Typography>
              </Box>
            ))
          }

        </Box>
      </Box>

    return( 
      <Container className="has-footbar" style={{ maxWidth:"100%", paddingLeft:0, paddingRight:0 }}>
        <Headbar title={"Storyboard Settings"} breadcrumbs={false} />
        <Box className="app_container">
          {basic_settings_widget}
        </Box>
        <Footbar buttons={[cancelBtn, saveBtn]} /> 
      </Container>

    )
}

export default SettingsInspector
