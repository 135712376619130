import React, { useContext } from 'react';
import AppContext from "../utils/app-context";
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import theme from "../styles/theme";
import { useHistory } from "react-router-dom";
import { analytics } from "../utils/firebase.js";
import { logEvent } from "firebase/analytics";


const styles = {
    root: {width: '100vw',
        height: '100vh',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    homeContainer: {
        width: '100vw',
        height: '100vh',
        maxWidth: '1000px',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    homeHero: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: '#000000',
        height: '100%',
    },
    text: {
        fontSize: '2em',
        marginRight: '10px',
    },
    textHeader: {
        fontSize: '3em',
        marginRight: '10px',
    },
    textMobile: {
        fontSize: '4vw',
        marginLeft: '5px',
        marginRight: '5px'
    },
    textHeaderMobile: {
        fontSize: '6vw',
        marginLeft: '5px',
        marginRight: '5px'
    },
    heroCTAButton: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
        marginTop: '20px',
        marginBottom: '10px',
        borderRadius: '30px',
        fontSize: '2em',
    },

}


export default function About(props) {
    const classes = styles;
    const { state } = useContext(AppContext);

    const history = useHistory();

    const handleContribute = () => {
        logEvent(analytics, 'BUTTON_TO_RECORDER');
        history.push('/recorder');
    }

    //const handleAbout = () => {
    //    history.push('/about');
    //}

    // const cornerCTA = <Button variant="outlined" size="large" className={classes.cornerCTAButton} onClick={handleAbout}>About</Button>

    const mainCTA = (<>
            <Button variant="contained" size="large" className={classes.heroCTAButton} onClick={handleContribute}>Let's dance</Button>
            {/* <Typography className={state.app.isMobile ? classes.textORMobile : classes.textORSub}> OR </Typography>
            <Button variant="contained" size="large" className={state.app.isMobile ? classes.heroCTAButtonMobile2 : classes.heroCTAButton2} onClick={handleGift}>Give the gift of Moments</Button> */}
        </>)

    return (
        <div className={classes.root}>
            <Container className={classes.homeContainer}>
                <div className={state.app.isMobile ? classes.homeHeroMobile : classes.homeHero}>
                    <br></br>
                    <Typography className={state.app.isMobile ? classes.textHeroSubMobile : classes.textHeroSub}>Project Host: Anna & Philip </Typography>

                    <br></br>
                    <br></br>
                    {/* <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>What is this project?</Typography> */}
                    <div className={state.app.isMobile ? classes.heroSubBoxMobile : classes.heroSubBox}>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}>Let's make a video for Zela's birthday!  Can you record a clip for her?  We are looking for videos of her friends dancing. Sound won't be used.  </Typography>
                        <br></br>
                        <br></br>
                        {/* 
                        <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>What is Okapi?</Typography>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}>Okapi helps you make video collaboratively.  Okapi is a technology start up, which happens to be founded by Philip.  This is a prototype web app.  </Typography>
                        <br></br>
                        <br></br>
                    */}


                        <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>What music?</Typography>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}> It is fine to dance to any music.  In the end it will be set to "Wild World" by Cat Stevens. Are the kids ready to dance? </Typography>
                        <br></br>
                    </div>
                    {mainCTA}


                </div>
            </Container>
        </div>
    )
}