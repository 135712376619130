import React, { useState, useEffect, useContext, useRef } from 'react';
import AppContext from "../utils/app-context";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowBackIos, ArrowForwardIos, Close, ContentCopy, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import ZebraPlayer from './zebra-player';
import theme from '../styles/theme';
import { sortTakes } from '../utils/utility';

const TakesPreviewDialog = ({ selectedTakePreview, children }) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation()

  const [currentTakeIndex, setCurrentTakeIndex] = useState(0);
  const [currentShotIndex, setCurrentShotIndex] = useState(0);
  const [takesByShot, setTakesByShot] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const currentShotIDRef = useRef()

  const currentShot = takesByShot[currentShotIndex];
  const currentTakes = currentShot ? currentShot[1] : [];
  const isLastTake = currentTakeIndex === currentTakes.length - 1;
  const isFirstTake = currentTakeIndex === 0;
  const currentSelectedTake = currentTakes[currentTakeIndex]

  const currentStory = state.stories.activeStory;
  const allTakes = state.stories.activeTakeList;
  let previewVolume = state.app.previewVolume;
  const veneerLUT = state.stories.activeVeneerLUT;

  const successStyles = {
    position: "absolute",
    top: "20px",
    left: "110px",
    fontSize: "9px",
    lineHeight: "12px",
    textTransform: "lowercase",
    transition: ".5s all",
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleVolume = () => {
    previewVolume = (selectedTakePreview === 1) ? 0 : 1;
    let volume = previewVolume;
    dispatch({ type: "TOGGLE_VOLUME", payload: { volume } });
  }

  const copyPrompt = (type) => {
    navigator.clipboard.writeText(veneerLUT[currentSelectedTake.id].prompt);
    setShowSuccess(true);
    const timer = setTimeout(() => {
      setShowSuccess(false);
      //setShowSelectedSuccess(false);
    }, 1500);
  }

  useEffect(() => {
    if (selectedTakePreview) {
      setOpen(true);
    }
    if (allTakes && allTakes.length > 0 && selectedTakePreview) {
      const groupedTakes = allTakes.reduce((acc, take) => {
        if (!acc[take.shot_id]) {
          acc[take.shot_id] = [];
        }
        acc[take.shot_id].push(take);
        return acc;
      }, {});

      const orderedShots = currentStory.parts[0].order
        .map((shotId) => [shotId, sortTakes(groupedTakes[shotId] || [])])
        .filter(([, takes]) => takes.length > 0);

      setTakesByShot(orderedShots);

      const initialShotIndex = orderedShots.findIndex(([shotId]) => shotId === selectedTakePreview.shot_id);
      const initialTakeIndex = initialShotIndex !== -1 ? groupedTakes[selectedTakePreview.shot_id].findIndex(take => take.id === selectedTakePreview.id) : 0;

      setCurrentShotIndex(initialShotIndex !== -1 ? initialShotIndex : 0);
      setCurrentTakeIndex(initialTakeIndex !== -1 ? initialTakeIndex : 0);
    }
  }, [allTakes, selectedTakePreview, currentStory]);

  useEffect(() => {
    const currentShotId = takesByShot[currentShotIndex]?.[0];
    if (currentShotId) {
      history.replace(`/p/${currentStory.portal_id}/${currentShotId}`);
    }
  }, [currentShotIndex, takesByShot, currentStory.portal_id]);


  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const newShotId = pathParts[pathParts.length - 1];
    currentShotIDRef.current = newShotId
  }, [location]);

  const handleNextTake = () => {
    if (!selectedTakePreview) {
      const order = state.stories.activeStory.parts[0].order;
      const currentIndex = order.indexOf(currentShotIDRef.current);
      if (currentIndex !== -1 && currentIndex < order.length - 1) {
        const nextShotId = order[currentIndex + 1];
        history.replace(`/p/${state.stories.activeStory.portal_id}/${nextShotId}`);
      } else {
        console.log('Already at the last shot, cannot navigate next.');
      }
      return
    }

    const currentTakes = sortTakes(takesByShot[currentShotIndex][1]);

    if (currentTakeIndex < currentTakes.length - 1) {
      setCurrentTakeIndex(prevIndex => prevIndex + 1);
    } else if (currentShotIndex < takesByShot.length - 1) {
      setCurrentShotIndex(prevIndex => prevIndex + 1);
      setCurrentTakeIndex(0);
    }
  };

  const handlePreviousTake = () => {
    if (!selectedTakePreview) {
      const order = state.stories.activeStory.parts[0].order;
      const currentIndex = order.indexOf(currentShotIDRef.current);
      if (currentIndex > 0) {
        const previousShotId = order[currentIndex - 1];
        history.replace(`/p/${state.stories.activeStory.portal_id}/${previousShotId}`);
      } else {
        console.log('Already at the first shot, cannot navigate previous.');
      }
      return;
    }

    if (currentTakeIndex > 0) {
      
      setCurrentTakeIndex(prevIndex => prevIndex - 1);
    } else if (currentShotIndex > 0) {
      setCurrentShotIndex(prevIndex => prevIndex - 1);
      const previousShotTakes = sortTakes(takesByShot[currentShotIndex - 1][1]);
      setCurrentTakeIndex(previousShotTakes.length - 1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      handlePreviousTake();
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      handleNextTake();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentTakeIndex, currentShotIndex, takesByShot]);

  return (
    currentTakes.length ?
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
        sx={{ margin: "0 30px" }}
      >
        <DialogTitle sx={{ padding: "1em 1.5em 0", [theme.breakpoints.down("md")]: { padding: "1em 1em 0" } }}>
          View Take

          <Box onClick={handleClose} sx={{ float: "right", "&:hover": { cursor: "pointer" } }}>
            <Close className="icon" />
          </Box>
          {
            <Box onClick={toggleVolume} sx={{ float: "right", marginRight: "0.5rem", "&:hover": { cursor: "pointer" } }}>
              {previewVolume === 1 ? (
                <VolumeUp className="icon" />
              ) : (
                <VolumeOff className="icon" />
              )}
            </Box>
          }
        </DialogTitle>
        <DialogContent sx={{ position: 'relative', padding: '0 2em 0', [theme.breakpoints.down('md')]: { padding: '0 1em' } }}>
          <Box key="preview" style={{ boxShadow: "none" }}>
            {
              (currentTakes.length > 0 && currentSelectedTake?.stream_path)
                ?
                <ZebraPlayer video_urls={[currentSelectedTake.stream_path]} volume={0} light={false} playing={true} controls={false} style={{ border: 0 }} />
                :
                <img style={{ width: '100%' }} src="https://10zebra.app/walking.gif" alt="loading" />
            }
          </Box>
          {(state.app.userIsStoryOwner || (!state.app.userIsStoryOwner && state.app.settings.promptsVisible)) && currentSelectedTake && currentSelectedTake.id in veneerLUT && <Box sx={{ paddingTop:'15px', position:'relative' }}>
              <Typography variant="bold" sx={{ position:'relative', bottom:'6px'}}>Prompt  </Typography>
              <ContentCopy sx={{ '&:hover': { cursor:'pointer', opacity:'.6', transition:'.5s all' } }} onClick={ () => { copyPrompt() }} />  
              {showSuccess && <Box className="copy-success" sx={ successStyles }>Prompt Copied!</Box> }
              <Typography> {veneerLUT[currentSelectedTake.id].prompt} {"  "} </Typography>
            </Box>}
        </DialogContent>
        {children}

        <Box sx={{ position: "fixed", maxWidth: "1025px", zIndex: "99999", top: "0", left: "0", right: "0", pointerEvents: "none", width: "100%", height: "100%", margin: "0 auto" }}>
          {!(isFirstTake && currentShotIndex === 0) && (
            <Box onClick={handlePreviousTake}>
              <Tooltip title={isFirstTake ? "Previous Shot" : "Previous Take"}>
                {isFirstTake && currentShotIndex > 0 ? (
                  <KeyboardDoubleArrowLeft
                    sx={{
                      marginLeft: "10px",
                      fontSize: "48px",
                      color: "#fff",
                      cursor: "pointer",
                      position: "absolute",
                      top: "calc(50% - 20px)",
                      left: "0",
                      pointerEvents: "all",
                      padding: '4px',
                    }}
                  />
                ) : (
                  <ArrowBackIos
                    sx={{
                      marginLeft: "10px",
                      fontSize: "32px",
                      color: "#fff",
                      cursor: "pointer",
                      position: "absolute",
                      top: "calc(50% - 20px)",
                      left: "0",
                      pointerEvents: "all",
                      padding: '4px',
                    }}
                  />
                )}
              </Tooltip>
            </Box>
          )}

          {!(isLastTake && currentShotIndex === takesByShot.length - 1) && (
            <Box onClick={handleNextTake}>
              <Tooltip title={isLastTake ? "Next Shot" : "Next Take"}>
                {isLastTake && currentShotIndex < takesByShot.length - 1 ? (
                  <KeyboardDoubleArrowRight
                    sx={{
                      marginRight: "10px",
                      fontSize: "48px",
                      color: "#fff",
                      cursor: "pointer",
                      position: "absolute",
                      top: "calc(50% - 20px)",
                      right: "0",
                      pointerEvents: "all",
                      padding: '4px',
                    }}
                  />
                ) : (
                  <ArrowForwardIos
                    sx={{
                      marginRight: "10px",
                      fontSize: "32px",
                      color: "#fff",
                      cursor: "pointer",
                      position: "absolute",
                      top: "calc(50% - 20px)",
                      right: "0",
                      pointerEvents: "all",
                      padding: '4px',
                    }}
                  />
                )}
              </Tooltip>
            </Box>
          )}
        </Box>
      </Dialog>
      : null

  );
};

export default TakesPreviewDialog;
