import React, { useContext, useState, Fragment, useEffect } from "react";
import { useHistory/*, Link*/ } from "react-router-dom";
import AppContext from "../utils/app-context";
import { AuthContext } from "../utils/auth-provider";
import "../components/pmm.css"
import RecordableShotGroup from "../components/recordable-shot-group";
import AlertUploadStatus from './alert-upload-status';
import ShareDialog from "../components/share-dialog";
import ViewToggle from "../components/toggle-view";
import ReactPlayer from "react-player"
import { Link, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography, Icon, Menu, Container, Box, Button, MenuItem, FormControl, InputLabel, OutlinedInput, Input } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import AlertImportStoryboardStatus from './alert-import-storyboard';
import Headbar from "./Headbar";
import Footbar from "./Footbar";
import Breadcrumbs from "./Breadcrumbs";
import theme from '../styles/theme'
import EditableField from "../components/editable-field";
import { nanoid } from "nanoid";
import { v4 as uuidv4 } from 'uuid';
import useMediaQuery from '@mui/material/useMediaQuery';
import firebase from '../utils/firebase';
// import addShot from "../helpers/index";

import ZebraPlayer from "../components/zebra-player"; 
import {computeSequence, computePercentFilmed} from '../utils/sequence.js';

import navLogo from '../images/nav-logo.svg'
import { css } from '@emotion/css'
import { withStyles } from '@mui/styles';
import toast from "react-hot-toast";
import Messages from "../constants/app.js";

import DropUpload from "./drop-upload";

const init_shots = [{
    header: 'Sample Shot',
    description: 'A short descrition so contributors know what to film.  Like "People having fun as they wait in line to enter"',
    meta: 'Outside the venue',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg"
  }
]
const styles = {
  footerWrap: {
    textAlign: 'center'
  },
  DialogBody: {
    textAlign: 'center'
  },
  viewAsStrip:{
    textDecoration:'underline', 
    cursor:'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '16px',
    color: 'black',
    padding: '1rem 1.5rem 0rem 0rem',
  }
}

function Produce(props) {
    const [displayImages, setDisplayImages] = useState(true)
    //const [madeEdit, setMadeEdit] = useState(false);
    const [displayShare, setDisplayShare] = useState(true)
    const [viewLatestTake, setViewLatestTake] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [viewPreview, setViewPreview] = useState(false);
    const [importFormOpen, setImportFormOpen] = React.useState(false)
    const [selectedShot, setSelectedShot] = React.useState(null)
    const [selectedItem, setSelectedItem] = React.useState(null) 
    const [showShare, setShowShare] = React.useState(false) 
    const toggleShare = () => setShowShare(p => !p)
    const [selectedShotAction, setSelectedShotAction] = React.useState(null) 
    const [imageSelectorOpen, setImageSelectorOpen] = React.useState(false)
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [urlValid, setUrlValid] = React.useState(true);
    const [isDragOver, setIsDragOver] = useState(false);
    const [selectableTakeList, setSelectableTakeList] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=0nXB-Bgx1eM");

    const classes = styles


    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();

    const story = state.stories.activeStory //state.story //state.stories.activeStory
    const takes = state.stories.activeTakeList // this is all takes in the story
    const shots = state.stories.activeShotList // this is all undeleted shots
    const portal_id = (state.stories.activeStory.portal_id) ? state.stories.activeStory.portal_id : state.app.portal.id
    const fixed = false
    const medScreen = (useMediaQuery(theme.breakpoints.down("md"))) ? true : false
    const title = props.title ? props.title : story.title ? story.title : "Untitled";
 
    /*const handleDragEnd = (event) => {
      const {active, over} = event;
      //alert('drop');
      //console.log('handleDragEnd active', active)
      //console.log('handleDragEnd over', over)
  
      /*const activeContainer =  event.active.data.current.sortable.containerId;
      const overContainer =  event.over.data.current.sortable.containerId;
  
      const activeIndex =  event.active.data.current.sortable.index;
      const overIndex =  event.over.data.current.sortable.index;
  
      const source_part_id = story.parts[activeContainer].id
      const dest_part_id = story.parts[overContainer].id
      const moving_shot_id = active.id  // columns[activeContainer].items[source.index].id /// columns[activeContainer].items[oldIndex].id
      let preceding_shot_id = '000'
      
      if (overIndex > 0) {
          const items = story.parts[overContainer].order.map(shot_id => shots.filter(shot => shot.id===shot_id)[0]).filter(s => s!==undefined)
          preceding_shot_id = items[overIndex - 1].id  
      }
  
      if ((activeIndex !== overIndex) || (activeContainer !== overContainer)) {
        const payload = {source_part_id: source_part_id, dest_part_id: dest_part_id, moving_shot_id: moving_shot_id, preceding_shot_id: preceding_shot_id}
        dispatch({ type: "MOVE_STORY_SHOT", payload: payload });
      }
      if (false) {
        if (active.id !== over.id) {
            const oldIndex = columns[activeContainer].items.findIndex(({id}) => id === active.id);
            const newIndex = columns[overContainer].items.findIndex(({id}) => id === over.id);
            const result = arrayMove(columns[overContainer].items, oldIndex, newIndex);
  
            //console.log('oldindex', columns[activeContainer].items)
            //console.log('newindex', result)
            if(activeContainer !== overContainer) {
              const sourceColumn = columns[activeContainer];
              const destColumn = columns[overContainer];
              const sourceItems = [...columns[activeContainer].items];
              const destItems = [...columns[overContainer].items];
              //console.log('destColumn', sourceColumn)
              //console.log('destColumn', destColumn);
              //console.log('sourceItems',sourceItems);
              //console.log('destItems', destItems);
              const [removed] = sourceItems.splice(oldIndex, 1);
              destItems.splice(newIndex, 0, removed)
  
              // setColumns({
              //   ...columns, 
              //   [activeContainer]: {
              //     ...sourceColumn,
              //     items: sourceItems
              //   },
              //   [overContainer] : {
              //     ...destColumn,
              //     items: destItems
              //   }
          
              // })
  
              return true
            }
  
            // setColumns(columns => ({
            //   ...columns,
            //   [activeContainer]: {
            //     ...columns[activeContainer],
            //     items: result
            //   }
            // }))
  
            return true
        }
      } *
    }*/

  const [isDeleted, setIsDeleted] = useState(false);
  
  useEffect(()=>{
    setIsDeleted(story.deleted_at && story.deleted_at.seconds !== null)
  },[story])

    function share() {
      setShowShare(true)
    }

    function setStoryTitle(story_id, title) {
      const payload = { proj_id: story_id, title: title };
      dispatch({ type: "UPDATE_STORY_TITLE", payload: payload });
    }

    function toggleDisplayView() {
      setDisplayImages(!displayImages) 
    }

    function togglePreview() {
     setViewPreview(!viewPreview) 
      window.scrollTo(0, 0)
    }

    function runTest() {
      alert("all tests passed") 
    }

    function handleShotDelete(shot) {
      const msg = `would delete ${shot.id}`
      console.log(msg)
      const payload = {shot_id: shot.id}
       dispatch({ type: "DELETE_STORY_SHOT", payload: payload });
       // setMadeEdit(true);
    }

    function doShare() {
          alert("Text or email this link to let others add video:\nhttps://okapi-webdev.web.app/p/" + state.app.portal.id) 
    }

    function handleUrlFormChange(e) {
      const url = e.target.value
      const valid_start = "www.youtube.com/watch?v="
      const valid_short_code_length = 11
      if (url.includes(valid_start) && url.substr(url.lastIndexOf(valid_start) + valid_start.length).length===valid_short_code_length){
          setUrlValid(true)
      } else {
          setUrlValid(false)
          //console.log("bad short code", url.substr(url.lastIndexOf(valid_start) + valid_start.length))
      }
      setImportUrl(url)
    }

    /*function importStoryboard() {

      setImportFormOpen(false)

      if (!urlValid) return

      const storyRef = state.stories.activeStory.ref
      if (storyRef) {
          const created = new Date()
          const date = created.getHours() + ':' + ((created.getMinutes() < 10) ? '0'+created.getMinutes() : created.getMinutes()) + ' ' + created.toLocaleString('default', { month: 'short' }) + ' ' + created.getDate() + ', `' + created.getFullYear().toString().slice(2)
          const import_req_id = uuidv4() // '1234' // 
          const import_request = {type: "storyboard", 
                        url: importUrl,
                        //"https://www.youtube.com/watch?v=aMlt1I-W5EM", 
                        // amknI7qr25g W86cTIoMv2U", 
                        // aMlt1I-W5EM  7 sec works
                        // https://www.youtube.com/watch?v=qBNmY7S0BG8  12sec stop motion breaks
                        // QpiGPLUCC3w
                        // https://www.youtube.com/watch?v=adLGHcj_fmA   bruno
                        //location: "the/user/the/story",
                        proxyLUT : state.app.settings.ytImportIsBlackAndWhite ? "gray_none" : "none",
                        title: date.toString(), 
                        created_at: created.getTime(),
                        completed_at: null}; 

          const importRequestRef = storyRef.collection('imports').doc(import_req_id)
          importRequestRef.set(import_request)
              .then(() => {
                dispatch({type: "START_IMPORT", payload: {}});
                toast.success(Messages.info.autoStoryBoardStarted)
              })
          .catch((error) => {
            toast.error(Messages.error.errorCreatingImportRequest )
          })
         
      } else {
          toast.error(Messages.error.noStoryError)
      }
    }*/

    function doInfo() {
        const msg = "Help us collect footage to make a video that follows this storyboard. Tap the image in any planned shot to upload a new take 1-6 seconds long. We prefer horizontal videos (cell phone sideways).  Thank you!"
        const msg2 = msg + `\n\n id: ${state.app.portal.id}`
        alert(msg)
      }

    function doEdit() {
          //todo:  consider when the story selected is not last active project ...
          //dispatch({ type: "UPDATE_ACTIVE_STORY_REF" })
          // userRef.update({ lastActiveStory: activeStoryRef })
          history.push('/p/' + portal_id);
    }

    function clearSelectedShotAndItem() {
        setSelectedShot(null)
        setSelectedItem(null)
        setSelectedShotAction(null)
    }

    const vestigal_header =         <div className="filters btn-group stack-exception">
          <button type="button" className="btn toggle-image-display" onClick={toggleDisplayView}> View </button>
          <button type="button" className="btn toggle-image-display" onClick={runTest}> Test </button>
          <button type="button" className="btn toggle-image-display" onClick={doShare}> Share </button>
        </div>
    
    const headNavStyles= {
      padding:'1em', 
      backgroundColor: theme.palette.primary.main, 
      display:'flex', 
      justifyContent:'space-between'
    }
    const subNavStyles= {
      padding:'1em 2em', 
      borderBottom: '1px solid ' + theme.palette.primary.main, 
      display:'flex', 
      justifyContent:'space-between', 
      marginBottom:'1em', 
      [theme.breakpoints.down('md')]: {
        padding:'1em', 
      }
    }

    const contStyles = {
      padding:'3em',
      minHeight:'30vh', 
      "h1": {
        marginBottom: "15px", 
        width: "75%", 
        "textarea": {
          fontSize: "22px", 
          lineHeight: "32px", 
          fontFamily: "Montserrat, Roboto, Helvetica, sans-serif"
        }, 
      }, 
      ".description textarea": {
          fontSize: "16px", 
          lineHeight: "20px", 
          fontFamily: "Montserrat, Roboto, Helvetica, sans-serif"
      }, 
      [theme.breakpoints.down('md')]: {
        padding:'1.5em',
      }
    }

    const dynamicUploadStyles = {
      position: 'fixed',
      bottom: medScreen ? '90px' : '60px',
      left: medScreen? '50%':'40%',
      transform: 'translateX(-50%)',
      whiteSpace: 'nowrap',
      width: 'auto',
      maxWidth: '100%',
      zIndex:'99'
    };
  
    const vestigal_share1 =   <button type="submit" className="btn__inline" onClick={doShare}> Share </button>


    const sequence = computeSequence(story, shots, takes)
    const filmed = computePercentFilmed(story, shots, takes)
    

    function setSelectedShotAndAction(shot, action) {
      setSelectedShot(shot)
      setSelectedShotAction(action)
    }

    //const image_selector_inds = [...Array(9).keys()]
    useEffect(() => {
      setSelectableTakeList(selectedShot ? takes.filter(take => take.shot_id==selectedShot.id) : [])
    }, [selectedShot])


    const import_Dialog = 
        <Dialog id="import"
          onClose={() => setImportFormOpen(false)}
          onOpen={() => setImportFormOpen(true)}
          open={importFormOpen}
        >
          <DialogTitle>Auto Storyboard</DialogTitle>
          <DialogContent>
            <div>
            <Box>
              <p>
                Every scene from the first 60 seconds of a video will become a panel in your storyboard.
              </p>
            </Box>
              {/*<DropzoneArea
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                previewChipProps={{classes: { root: classes.previewChip } }}
                previewText="Selected files"
              />*/}
              <div className="app_container">
                <DropUpload maxFiles={1} type="story-import" mediaRestrict={["video"]} dropFunc={() => setImportFormOpen(false) }  />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
          { !urlValid && <Box onClick={() => setImportFormOpen(false)}>Invalid URL</Box> }
            <Button variant='outlined' onClick={() => setImportFormOpen(false)}>
              Close
            </Button>
            { /*urlValid && 
              <Button
                labelPosition='right'
                icon='checkmark'
                onClick={importStoryboard}
                positive
                variant='contained'>Create new panels</Button>
            */}
          </DialogActions>
        </Dialog>

    const take_selector_Dialog = 
      <Dialog id="image_selector"
        onClose={() => setImageSelectorOpen(false)}
        onOpen={() => setImageSelectorOpen(true)}
        open={selectedShotAction=='select_take'}
      >
        <DialogTitle>
            <Typography variant="h2">Select a take</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
              <Box>
                <Typography variant="h3">Which take do you want to use?</Typography>
              </Box>
            </Box>

            <Grid container columnSpacing={{ xs: 2, md: 8 }}>
              {selectableTakeList.map((item, key) => {
                <Grid xs={4} md={2} key={key}>
                  <Item> 
                    {!selectedItem &&  <Box component="img" src={item.thumbnail} fluid="true"  onClick={() => selectItemForShot(item)}/>}
                    {selectedItem && item.thumbnail!=selectedItem.thumbnail && <Box component="img" src={item.thumbnail} fluid="true"  onClick={() => selectItemForShot(item)}/>}
                    {selectedItem && item.thumbnail==selectedItem.thumbnail && <ZebraPlayer video_urls={[item.stream_path]} light={false} controls={false} />}
                    {selectedItem && item.thumbnail==selectedItem.thumbnail && "✓"}
                  </Item>
                </Grid>
          })}
            </Grid>

        </DialogContent>

        <DialogActions>
          <Button 
            onClick={clearSelectedShotAndItem}
          >
            Cancel
          </Button>
          { selectedItem && selectedItem.thumbnail && selectedItem.thumbnail.search('walking.gif') == -1 && <Button onClick={applySelectItemForShot}> Select </Button>}

        </DialogActions>
      </Dialog>


    function applySelectItemForShot() {
      if (selectedItem &&  selectedShot) {
            const take_id = selectedItem.id
            const shot_selected_take_payload = {shot_id: selectedShot.id, take_id: take_id, image: selectedItem.thumbnail, stream_path: selectedItem.stream_path}
            console.log("UPDATE_SHOT_SELECTED_TAKE", shot_selected_take_payload)
            dispatch({ type: "UPDATE_SHOT_SELECTED_TAKE", payload: shot_selected_take_payload });
      }
      clearSelectedShotAndItem()
    }

    function selectItemForShot(item) {
      // console.log("selected item - beta: ", item) 
      // console.log("selectedShot - beta", selectedShot)
      setSelectedItem(item)
    }

    const pct_filmed_str = filmed['pct_str']
    const last_cut_published = true     // move to portal.last_cut_published? or story.last_cut_published?
    const live_view_pct_threshold = 0 // move to portal.live_view_pct_threshold?
    // const live_draft_published = true // move to portal.live_draft_published?
    const show_last_cut = last_cut_published && 'last_cut' in story && story.last_cut !== null
    const show_live_preview =  !show_last_cut && (filmed['frac'] > live_view_pct_threshold) && 'url' in sequence

    const modified_nav = <Box component="header" className="headNav" sx={headNavStyles}>
          <Link position='left' href="/">
            <img src={navLogo} />
          </Link>
      </Box>
    const modified_sub_nav =  <Box  component="header" className="subNav"  sx={subNavStyles}>
            <Typography variant='h2'>     
                {story.title ? story.title : (state.app.userIsStoryOwner) ? "Untitled" : "Collaborative Video"}
            </Typography>
            <Breadcrumbs />
            {false &&  <ViewToggle viewLatestTake={viewLatestTake} setViewLatestTake={setViewLatestTake}/>} 

            {'frac' in filmed && <Box sx={{ alignSelf:'center' }}>
                { `Filmed: ${pct_filmed_str}%` }                
            </Box>} 
      </Box>

    const description = story.description ? story.description : "Let's make a video together. Tap any image below to contribute a 1-6 second clip. Thanks! "
    

    function setStoryDesc(story_id, desc) {
      const payload = {proj_id: story_id, description: desc}
      dispatch({ type: "UPDATE_STORY_DESCRIPTION", payload: payload });
    }

    const backBtn = { href:'/storyboards', type:'tertiary', text:'Back' }
    const nextBtn = { href:'/p/'+portal_id+'/preview', type:'primary', text:'Preview' }
let footLinks = [backBtn, nextBtn];
const storyboardBackBtn = {click: () => { 
  history.push('/storyboards');
},type: "primary", text: "Back to Storyboards",};

if (!state.app.userIsStoryOwner) footLinks.shift();
return (
  <>
   
  <Container className="has-footbar" style={{ maxWidth: '100%', paddingLeft: 0, paddingRight: 0 }}>
    <Headbar
      title={!isDeleted && story.title ? story.title : (state.app.userIsStoryOwner ? "Untitled" : "Collaborative Video")}
      share={!isDeleted && true}
      fork={!isDeleted && true}
      filmed={!isDeleted && state.app.userIsStoryOwner && 'frac' in filmed ? `Filmed: ${pct_filmed_str}%` : false}
      settings={!isDeleted &&{ storyboard: { title: 'Auto Storyboard', action: setImportFormOpen } }}
      starred={true}
    />

    {import_Dialog}

    {isDeleted ? (
        <Box sx={{ display: 'grid', placeContent:'center', height:'60vh' }}>
       This Project has been deleted.
      </Box>
    ) : (
      <>
        { viewPreview && (
          <Box>
            {show_live_preview && (
              <div>
                <ZebraPlayer video_urls={sequence['url']} light={false} controls={false} />
              </div>
            )}

            {show_last_cut && (
              <div>
                <ZebraPlayer video_urls={[story.last_cut.stream_path]} light={false} controls={false} />
              </div>
            )}

            {(!show_last_cut && !show_live_preview) && (
              <div className="app_container">
                <Box container sx={{ textAlign: 'center', height: '7vh', verticalAlign: 'middle' }}>
                  <Box style={{ maxWidth: 450 }}>
                    <Typography variant="body1">
                      Preview will be visible when {'>'} 50% is filmed.
                    </Typography>
                  </Box>
                </Box>
              </div>
            )}
          </Box>
        )}

        <Box className="Scene" sx={contStyles}>
          <Box className="title">
            {state.app.userIsStoryOwner && medScreen && (
              <Typography variant="h1">
                <EditableField
                  init={title}
                  setter={setStoryTitle}
                  story_id={story.title}
                  textClassName="input input__cd title_cd"
                  num_rows={1}
                  max_length={500}
                />
              </Typography>
            )}
            {!state.app.userIsStoryOwner && medScreen && (
              <Typography variant="h1">
                {title}
              </Typography>
            )}
          </Box>

          <Box className="description" sx={{ marginBottom: '25px', width: '75%', maxWidth: '750px' }}>
            {state.app.userIsStoryOwner && (
              <Typography variant="body2">
                <EditableField
                  init={description}
                  setter={setStoryDesc}
                  story_id={story.portal_id}
                  textClassName="input input__cd title_cd"
                  num_rows={4}
                  max_length={500}
                />
              </Typography>
            )}
            {!state.app.userIsStoryOwner && (
              <Typography variant="body2">
                {description}
              </Typography>
            )}
          </Box>

          <Dialog>
            <DialogTitle id="alert-dialog-instruction-title">
              Instructions
            </DialogTitle>
            <DialogContent className={classes.DialogBody}>
              <Typography variant="body1" id="alert-dialog-description">
                {story.instructions ? story.instructions : "Help us collect footage to make a video that follows this storyboard. Tap the image in any planned shot to upload a new take 1-6 seconds long. We prefer horizontal videos (cell phone sideways). Thank you!"}
              </Typography>
              <br />
              <Button onClick={() => setShowDialog(!showDialog)}>
                OK
              </Button>
            </DialogContent>
          </Dialog>

          <ShareDialog open={showShare} toggleShare={toggleShare} />
          {story.parts && story.parts.map((part, key) => (
            <RecordableShotGroup
              id={key}
              title={part.title}
              displayImages={displayImages}
              viewLatestTake={viewLatestTake}
              handleShotDelete={handleShotDelete}
              shots={part.order.map(shot_id => shots.find(shot => shot.id === shot_id))}
              takes={takes}
              portal={portal_id}
              part_id={part.id}
              // addShot={addShot}
              setSelectedShotAndAction={setSelectedShotAndAction}
              key={key}
              />
            ))}
        </Box>
            {state.app.upload &&
              <div style={dynamicUploadStyles}>
                <AlertUploadStatus />
              </div>
            }
      </>
    )}

{!isDeleted ? <Footbar buttons={footLinks} />:<Footbar buttons={[storyboardBackBtn]} /> }
    
  </Container> 
 


  </>
);
}
export default Produce
