import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Tab,
  ToggleButton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../components/pmm.css";
import AppContext from "../utils/app-context";

import firebase from '../utils/firebase';
import { serverTimestamp } from "firebase/firestore";

import theme from "../styles/theme";
import Footbar from "./Footbar";
import Headbar from "./Headbar";

import ReactPlayer from "react-player";
import ZebraPlayer from "../components/zebra-player";
import { computePercentFilmed, computeSequence } from "../utils/sequence.js";
import toast from "react-hot-toast";
import Messages from "../constants/app.js";

const styles = {
  contStyles: {
    padding: "1em 2em",
    minHeight: "30vh",
    [theme.breakpoints.down("md")]: {
      padding: "0 1em",
    },
  },
  wrapStyles: {
    padding: "3em",
    display: "flex",
    justifyContent: "center",
    "&.export-wrap": {
      alignItems: "center",
      ".preview": {
        flex: "2 1 0",
        margin: 0,
        height: "auto",
      },
      ".export": {
        alignItems: "flex-start",
        paddingLeft: "2em",
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: "1.5em",
      "&.export-wrap": {
        flexDirection: "column",
        ".preview": {
          order: 1,
        },
        ".export": {
          order: 2,
          padding: 0,
        },
        ".buttons": {
          textAlign: "center",
        },
      },
    },
  },
  previewStyles: {
    maxWidth: "864px",
    maxHeight: "466px",
    margin: "0 auto",
    textAlign: "center",
    width: "100%",
    height: "466px",
    background: theme.palette.secondary.contrastText,
    video: {
      width: "100%",
    },
    ".empty": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  panelStyles: {
    minHeight: "100px",
    marginBottom: "2em",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      minHeight: "0",
    },
  },
};

function Preview(props) {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const [tabValue, setTabValue] = useState(0);

  {
    /* TODO: having a local state caused problems b/c it would not update if there was a change with user 
     (isOwner has a delayed update and determines if you start out looking at LIVE or a view defined by settings)
      const [prioritizeLivePreview, setPrioritizeLivePreview] = React.useState(state.app.prioritizeLivePreview);
       now we use state.app.prioritizeLivePreview 
    */
  }

  const story = state.stories.activeStory;
  const shots = state.stories.activeShotList;
  const takes = state.stories.activeTakeList; // this is all takes in the story

  const portal_id = state.stories.activeStory.portal_id
    ? state.stories.activeStory.portal_id
    : state.app.portal.id;

  function doInfo() {
    const msg =
      "Help us collect footage to make a video that follows this storyboard. Tap the image in any planned shot to upload a new take 1-6 seconds long. We prefer horizontal videos (cell phone sideways).  Thank you!";
    const msg2 = msg + `\n\n id: ${state.app.portal.id}`;
    alert(msg);
  }

  const sequence = computeSequence(story, shots, takes);
  const filmed = computePercentFilmed(story, shots, takes);

  function setSelectedShotAndAction(shot, action) {
    setSelectedShot(shot);
    setSelectedShotAction(action);
  }

  const live_view_pct_threshold = 0; // move to portal.live_view_pct_threshold?
  // const live_draft_published = true // move to portal.live_draft_published?

  const show_last_cut =
    story.last_cut && story.last_cut.stream_path ? true : false; //last_cut_published && 'last_cut' in story && story.last_cut !== null
  const show_live_preview =
    filmed["frac"] > live_view_pct_threshold && "url" in sequence;
  const goCancel = () => {
    if (portal_id) history.push("/p/" + portal_id);
    else history.push("/storyboards");
  };

  const cancelBtn = { click: goCancel, type: "tertiary", text: "Back" };

  useEffect(() => {
    // If there is a last cut, set the default tab to "save"; otherwise, set it to "live"
    if (show_last_cut) {
      setTabValue("save");
    } else {
      setTabValue("live");
    }
  }, [show_last_cut, show_live_preview]);

  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  function saveThisCut() {
    const storyRef = state.stories.activeStory.ref;

    if (storyRef) {
      // use tgt render if possible
      //console.log('tgt_video_filenames:', sequence['tgt'])
      //console.log('tgt_video_filenames length:', sequence['files'].length, sequence['tgt'].length)
      const has_all_tgts_defined =
        sequence["tgt"] && !sequence["tgt"].includes(undefined);
      //console.log('has_all_tgts_defined:', has_all_tgts_defined)
      const do_tgt_cut =
        has_all_tgts_defined &&
        sequence["files"].length === sequence["tgt"].length;

      // ToDo?:  move creation (eg. cutRef.set) into AppContext via dispatch. then append 'cut.requester' = currentUser

      if (do_tgt_cut) {
        toast.success(Messages.info.savingCutTrimmed);
      } else {
        toast.success(Messages.info.savingCut);
      }

      const tgt_audio = story.last_audio ? story.last_audio : null; // 'media/tgt/manual/sizzle-640-360.m4v'. // consider setting to 'None' in some cases where audio won't align.  clips not in order, or empty shots, or short shots?
      dispatch({ type: "SAVE_CUT", payload: { "do_tgt_cut": do_tgt_cut, "sequence": sequence, "tgt_audio":tgt_audio} });

    } else {
      console.log("no STORY, failed to request a cut");
    }
  }
  const communityCanToggleLivePreview =state.app.settings.communityCanToggleLivePreview; /*&& show_last_cut && show_live_preview*/
  //const communityLiveButtonText = tabvalue == 'live' /*state.app.prioritizeLivePreview*/ ? "Live" : "Saved"

  return (
    <Container
      className='has-footbar'
      style={{ maxWidth: "100%", paddingLeft: 0, paddingRight: 0 }}
    >
      <Headbar
        title={
          story.title
            ? story.title
            : state.app.userIsStoryOwner
            ? "Untitled"
            : "Collaborative Video"
        }
        share={true}
        fork={true}
      />
      <Box
        sx={styles.wrapStyles}
        className={state.app.userIsStoryOwner ? "export-wrap" : "preview-wrap"}
      >

      
        
<Box
  sx={{ ...styles.previewStyles, background: "transparent" }}
  className="preview"
>
  {((communityCanToggleLivePreview || state.app.userIsStoryOwner) &&
  tabValue === "live" && 
  show_live_preview) || (!show_last_cut && show_live_preview) &&
  sequence["url"] ? (
    <Box sx={{ background: "#f8f8f8" }} className="live-preview">
      <ZebraPlayer
        video_urls={sequence["url"]}
        volume={false}
        light={false}
        controls={false}
        playing={true}
        loop={false}
        sx={{ width: "100%" }}
      />
    </Box>
  ) : tabValue === "live" && !state.app.userIsStoryOwner ? (
    <Box className="empty" sx={{ marginBottom: "4em" }}>
      <Typography variant="body1">
        Preview is currently not available.
      </Typography>
    </Box>
  ) : null}

  {tabValue === "save" && show_last_cut && (
    <div className="last-cut">
      <ReactPlayer
            className='react_player'
            url={story.last_cut.stream_path}
            controls={true}
            light={false}
            volume={state.app.settings.savedPreviewHasAudio ? 1 : 0}
            width='100%'
            height='100%'
      />
    </div>
  )}

          {!state.app.userIsStoryOwner && communityCanToggleLivePreview && (
    <Box
      className="community-toggle"
      sx={{
        display: "flex",
        padding: "4em",
        marginBottom: "7em",
        justifyContent: "center",
      }}
    >
            <Button
        disabled={!show_live_preview || story?.last_cut || story?.last_cut?.stream_path}
        variant={tabValue === "live" ? "contained" : "outlined"}
        onClick={(e) => {
          tabChange(e, "live");
        }}
      >
        Live
      </Button>
      <Button
        disabled={!story.last_cut || !story.last_cut.stream_path || show_live_preview}
        variant={tabValue === "save" ? "contained" : "outlined"}
        onClick={(e) => {
          tabChange(e, "save");
        }}
      >
        Saved
      </Button>
            </Box>
  )}

  {!show_last_cut && !show_live_preview && (
    <Box className="empty" sx={{ marginBottom: "4em" }}>
      <Typography variant="body1">
        Preview is currently not available.
      </Typography>
    </Box>
  )}
</Box>

        {state.app.userIsStoryOwner && (
          <Box className='export' sx={{ width: "300px" }}>
            <TabContext value={tabValue} aria-label='basic tabs'>
              <TabList onChange={tabChange}>
                <Tab label='Live Preview' value='live' />
                <Tab
                  label='Latest Save'
                  value='save'
                  disabled={!show_last_cut}
                />
              </TabList>
              <TabPanel value='live'>
                <Box sx={styles.panelStyles}>
                  <Box component='p'>
                    <Typography variant='body1'>
                      Now viewing the latest cut of your selected takes.
                    </Typography>
                  </Box>
                  <Box component='p'>
                    <Typography variant='body1'>
                      Save the cut to view an exportable high quality preview.
                    </Typography>
                  </Box>
                </Box>
                <Box className='buttons'>
                  <Button
                    variant='contained'
                    disabled={!takes.length || filmed.count == 0}
                    onClick={saveThisCut}
                  >
                    Save Cut
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value='save'>
                <Box sx={styles.panelStyles}>
                  <Typography variant='p'>
                    Now viewing the latest save.
                  </Typography>
                </Box>
                <ToggleButton
                  value='check'
                  //selected={movable}
                  onChange={() => {}}
                  sx={{
                    color: theme.palette.primary.contrastText,
                    border: 0,
                    justifyContent: "left",
                    padding: 0,
                    marginBottom: "10px",
                  }}
                >
                  {!true && <>Option 1</>}
                  {true && <>Option 2</>}
                </ToggleButton>
                <Box
                  className='buttons'
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {story.last_cut && (
                    <Button
                      icon='video'
                      href={story.last_cut.stream_path}
                      download='video.mp4'
                      target='_blank'
                      variant='contained'
                      color='secondary'
                      sx={{ marginBottom: "2em" }}
                    >
                      Download MP4
                    </Button>
                  )}
                  {story.last_export && (
                    <Button
                      labelPosition='right'
                      icon='zip'
                      variant='contained'
                      href={story.last_export}
                      download='fcp.zip'
                    >
                      Export Archive
                    </Button>
                  )}
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        )}
      </Box>

      <Footbar buttons={[cancelBtn]} />
    </Container>
  );
}

export default Preview;