import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppContext from "../utils/app-context";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Icon, Select, FormControl, MenuItem, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Unstable_Grid2";

import ReactPlayer from "react-player";
import EditableField from "./editable-field";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import assets from "../utils/assets";
import theme from "../styles/theme";
import ZebraPlayer from "./zebra-player";
import DropUpload from "./drop-upload";

export function SortableItem(props) {
  const { shot, setSelected, selected, drop, minimal } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: shot.id });

  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const [checkedAll, setCheckedAll] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  //const [vidPlayeo, setVidPlayeo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showVid, setShowVid] = useState(false);
  const [vidPlay, setVidPlay] = useState("");
  const [vidHover, setVidHover] = useState(false);
  const [playShot, setPlayShot] = useState(false);
  //const [isSelected, setIsSelected] = useState(true);
  const [checked, setChecked] = useState({
    nr1: false,
    nr2: false,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const dragStyles = {
    touchAction: "manipulation",
  };

  const moveIconStyles = {
    position:'relative',
    '.move-cursor': {
      display:'flex',
      justifyContent:'flex-end', 
      position:'relative',
      opacity:.1,
      transition:'opacity .5s ease-in-out',
    },
    '.delete-icon': {
      position:'relative',
      opacity:.1,
      transition:'opacity .5s ease-in-out',
      //transitionDelay:0, 
    },
    '.react_player': { aspectRatio:'16 / 9', height:'auto !important', },
    '&.selected' : {border:`3px solid ${theme.palette.primary.main}` },  
    '&:hover .move-cursor': {
      opacity:1,
      cursor:'grab'
    },
    '&:hover .delete-icon': {
      opacity:1,
      cursor:'pointer',
    },
    [theme.breakpoints.down('md')]: {
      '.move-cursor': {
        visibility:'visible',
      },
      '.delete-icon': {
        visibility:'visible',
      }
    }
  }
  //const counter = arr => arr.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});
  const takes_per_shot = props.takes
    ? props.takes.filter((take) => take.shot_id == shot?.id).length
    : null;
  const take_count = (
    <Box
      extra
      sx={{ position: "absolute", zIndex: "99", left: "5px", bottom: "5px" }}
    >
      <Link
        onClick={() => {
          history.push(`/p/${story.portal_id}/${shot.id}`);
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: ".05em .5em",
          backgroundColor: "#272727",
          color: "#fff",
          fontSize: ".7em",
          lineHeight: "2em",
          textDecoration: "none",
          transition: ".5s all", 
          "&:hover": {
            backgroundColor: "#B4B4B4",
            cursor: "pointer",
            color: "#000",
          },
        }}
      >
        <Box component="span">
          {takes_per_shot == undefined || takes_per_shot == 0
            ? "+"
            : takes_per_shot}
        </Box>
      </Link>
    </Box>
  );
  const story = state.stories.activeStory;
  const portal_id = props.portal;

  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  function setShotDescription(shot_id, description) {
    const payload = { shot_id: shot_id, description: description };
    dispatch({ type: "UPDATE_STORY_SHOT_DESCRIPTION", payload: payload });
  }

  function setShotTitle(shot_id, text) {
    const payload = { shot_id: shot_id, text: text };
    dispatch({ type: "UPDATE_STORY_SHOT_TITLE", payload: payload });
  }

  function editShotImage(shot) {
    if (!minimal)
      props.setSelectedShotAndAction(shot, "select_shot_image");
  }

  function editShotLastTakeIm(shot) {
    if (!minimal) props.setSelectedShotAndAction(shot, "select_take");
  }

  function expand() {
    setExpand(true);
  }

  function collapse() {
    setExpand(false);
  }

  const handleShotDelete = (shot) => {
    const msg = `would delete ${shot.id}`;
    console.log(msg);
    const payload = { shot_id: shot.id };
    dispatch({ type: "DELETE_STORY_SHOT", payload: payload });
  };

  let playTime;

  const stopPlaying = () => {
    setPlayShot(false);
  };

  const goTake = (id) => {
    history.push("/p/" + portal_id + "/" + id);
  };

  const handlePlayer = () => {
    const playerInit = () => {
      if (!showVid) setShowVid(true);
      setPlayShot(true);
      setVidHover(true);
    };

    if (shot.last_take_stream_url || shot.selected_take_stream_url) {
      playerInit();
    }
  };
  const handlePlay = () => {
    if (!vidPlay) setVidPlay("video-play");
  };
  let vidPlayer = null;
  if (shot.selected_take_stream_url)
    vidPlayer = (
      <ZebraPlayer
        sx={{ width: "100%", height: "100%" }}
        video_urls={[shot.selected_take_stream_url]}
        controls={false}
        playing={playShot}
        onEnded={stopPlaying}
        onPlay={handlePlay}
        loop={vidHover}
      />
    );
  else if (shot.last_take_stream_url)
    vidPlayer = (
      <ZebraPlayer
        sx={{ width: "100%", height: "100%" }}
        video_urls={[shot.last_take_stream_url]}
        controls={false}
        playing={playShot}
        onEnded={stopPlaying}
        onPlay={handlePlay}
        loop={vidHover}
      />
    );

    const itemChildren = <Item className={selected ? "selected" : ""} sx={moveIconStyles} onClick={(e) => { minimal && selectShot(e) }}>
    {!props.minimal && (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      padding: ".5em",
    }}
  >
    <Box className={"move-cursor"} {...listeners} sx={dragStyles}>
      <DragIndicatorIcon />
    </Box>
    {shot.takes ? (
      ""
    ) : (
      <Box className={"delete-icon"}>
        <DeleteIcon
          onClick={() =>
            window.confirm(
              "Are you sure you want to delete this shot?"
            ) == true
              ? props.handleShotDelete(shot)
              : null
          }
        />
      </Box>
    )}
  </Box>

)
}
<div className={`${props.filter === "storyboard" ? "" : "full-width"}`}>
  <div className="ui card">
    {props.displayImages &&
      props.viewLatestTake &&
      (shot.selected_take_im || shot.last_take_im) && (
        <Box
          className={`video-wrapper ${vidPlay}`}
          sx={{
            position: "relative",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={(e) => {
            !props.minimal && goTake(shot.id);
          }}
          onMouseOver={handlePlayer}
          onMouseOut={() => setVidHover(false)}
        >
          <Box
            component="img"
            sx={{ width: "100%", height: "auto", display: "block" }}
            className={"image"}
            src={shot.selected_take_im || shot.last_take_im}
          ></Box>{" "}
          {showVid ? <Box className={"video"}>{vidPlayer}</Box> : ""}
          {!props.minimal && take_count}
        </Box>
      )}
    {props.displayImages &&
      props.viewLatestTake &&
      !shot.last_take_im &&
      !shot.selected_take_im && (
        <Box
          className="imgWrapper"
          sx={{
            position: "relative",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={(e) => {
            !props.minimal && goTake(shot.id);
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              display: "flex",
              flexGrow: 1,
              background: "#B4B4B4",
            }}
            src={assets["empty_thumbnail"]}
            fluid="true"
            onClick={() => editShotImage(shot)}
            className={"imageWrapper"}
          ></Box>
          {/*} <input
        type="checkbox"
        name={`item-${shot.id}`}
        onChange={() => toggleCheck(`item-${shot.id}`)}
        checked={checked[`item-${shot.id}`]}
        className={'select-item'}
    /> */}
          {!props.minimal && take_count}
        </Box>
      )}
    {props.displayImages && !props.viewLatestTake && (
      <Box
        component="img"
        sx={{ width: "100%", height: "auto" }}
        src={shot.image}
        fluid="true"
        onClick={() => editShotImage(shot)}
        className={"imageWrapper"}
      ></Box>
    )}

    <Box sx={{ padding: ".5em" }}>
      {!props.minimal && (
        <Box sx={{ textAlign: "left" }}>
          {/*<Box component="label" color="black" >{shot.id.substring(0,3)}</Box>*/}
          <EditableField
            init={shot.header}
            setter={setShotTitle}
            shot_id={shot.id}
            textClassName="input input__cd title_cd"
            num_rows={1}
          />
        </Box>
      )}
      {props.minimal && (
        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {shot.header}
        </Box>
      )}
      {/*props.displayImages && <Card.Meta>{shot.meta}</Card.Meta>*/}
      {/*<Card.Description>{shot.description}</Card.Description>*/}
      {!props.minimal && (
        <EditableField
          init={shot.description}
          setter={setShotDescription}
          shot_id={shot.id}
          textClassName="input input__cd description_cd"
          num_rows={3}
        />
      )}
      {props.minimal && (
        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {shot.description}
        </Box>
      )}
    </Box>
  </div>
</div>
{/* </div> */}
</Item>

  const location = useLocation();

  const selectShot = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSelected();
  }
  useEffect(() => {
    setVidPlay("");
    setVidHover(false);
    vidPlayer = null;
  }, [location]);


  return (
      <Grid
        item
        xs={6}
        sm={4}
        md={3}
        lg={2}
        ref={setNodeRef}
        {...attributes}
        style={style}
        sx={{ touchAction:"none"}}

      >
        {!minimal &&
          <DropUpload part_id={props.part_id} next_shot_index={props.shots.length + 1} maxFiles={10} type="wrap" mode='shot' sx={{ touchAction:"none" }}>
            {itemChildren}    
          </DropUpload>
        }
        {minimal && 
          itemChildren
        }
      </Grid>
    

  );
}