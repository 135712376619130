import React, { useContext } from 'react';
import AppContext from "../utils/app-context";
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import theme from "../styles/theme";
import { useHistory } from "react-router-dom";
import assets from "../utils/assets"
import firebase, {analytics} from "../utils/firebase.js";
import { logEvent } from "firebase/analytics";



const styles = {
    root: {width: '100vw',
        height: '100vh',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    homeContainer: {
        width: '100vw',
        height: '100vh',
        maxWidth: '1000px',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    homeHero: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: '#000000',
        height: '100%',
    },
    text: {
        fontSize: '1.5em',
        marginRight: '10px',
    },
    textHeader: {
        fontSize: '3em',
        marginRight: '10px',
    },
    textMobile: {
        fontSize: '4vw',
        marginLeft: '5px',
        marginRight: '5px'
    },
    textHeaderMobile: {
        fontSize: '6vw',
        marginLeft: '5px',
        marginRight: '5px'
    },
    url_select: {
        borderRadius: '30px',
        fontSize: '1em',
        justifyContent: 'center',
        forceSelect: {  
          WebkitUserSelect: 'all',  /* Chrome 49+ */
          MozUserSelect: 'all',     /* Firefox 43+ */
          MsUserSelect: 'all',      /* No support yet */
          UserSelect: "all",        /* Likely future */   
        }
    }, 
    heroCTAButton: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
        marginTop: '20px',
        marginBottom: '10px',
        borderRadius: '30px',
        fontSize: '2em',
    },

}


const useCopyToClipboard = (text) => {
  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    
    // console.log("pre-copy", str,)
    // console.log("document", document)
    // console.log("selected", selected)
    //     console.log("el", el.value)


    const success = document.execCommand('copy');

    //console.log("post-copy success?", success)

    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    return success;
  };

  const [copied, setCopied] = React.useState(false);

  const copy = React.useCallback(() => {
    console.log("tried copy. is copied: ", copied )
    if (!copied) setCopied(copyToClipboard(text));
  }, [text]);
  React.useEffect(() => () => setCopied(false), [text]);

  return [copied, copy];
};



export default function Instructions(props) {
    const classes = styles
    const { state } = useContext(AppContext);

    const [project_link_copied, copy] = useCopyToClipboard('https://okapi-webdev.web.app/p/zela_bday_info');

    const history = useHistory();

    const handleContribute = () => {
        logEvent(analytics, 'BUTTON_TO_RECORDER');
        history.push('/recorder');
    }

    const mainCTA = (<>
            <Button variant="contained" size="large" className={classes.heroCTAButton} onClick={handleContribute}>Let's dance</Button>
            {/* <Typography className={state.app.isMobile ? classes.textORMobile : classes.textORSub}> OR </Typography>
            <Button variant="contained" size="large" className={state.app.isMobile ? classes.heroCTAButtonMobile2 : classes.heroCTAButton2} onClick={handleGift}>Give the gift of Moments</Button> */}
        </>)

    return (
        <div className={classes.root}>
            <Container className={classes.homeContainer}>
                <div className={state.app.isMobile ? classes.homeHeroMobile : classes.homeHero}>
                    <br></br>
                    <Typography className={state.app.isMobile ? classes.textHeroSubMobile : classes.textHeroSub}>Project Host: Anna & Philip </Typography>

                    {/* <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>What is this project?</Typography> */}
                    <div className={state.app.isMobile ? classes.heroSubBoxMobile : classes.heroSubBox}>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}>Let's make a video for Zela's birthday!  Can you record a clip for her?  We are looking for videos of her friends dancing.  </Typography>
                        <br></br>
                        {/* 
                        <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>What is Okapi?</Typography>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}>Okapi helps you make video collaboratively.  Okapi is a technology start up, which happens to be founded by Philip.  This is a prototype web app.  </Typography>
                        <br></br>
                        <br></br>
                    */}

                        <br></br>


                        <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>1. Choose device </Typography>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}>  From any camera phone, visit the current web page.  </Typography>
                        <br></br>
                
                        <div>
                            <Typography> https://okapi-webdev.web.app/p/zela_bday_info </Typography>
                            <button onClick={copy}>Copy</button>
                            <span>{project_link_copied && ' Link copied!'}</span>
                        </div>

                        <br></br>
                        <br></br>

                        <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>2. Get ready </Typography>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}> Motivate the dancer.  Consider your location and lighting. Your recorded sound won't be used. </Typography>

                        <br></br>
                        <br></br>

                        <Typography className={state.app.isMobile ? classes.textHeaderMobile : classes.textHeader}>3. Capture it </Typography>
                        <br></br>
                        <Typography className={state.app.isMobile ? classes.textMobile : classes.text}> It is fine to dance to any music. In the end it will be set to "Wild World" by Cat Stevens.  </Typography>
                        <br></br>

                    </div>
                    {mainCTA}


                </div>
            </Container>
        </div>
    )
}